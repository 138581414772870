import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
//mport { Helmet } from 'react-helmet'
import Header from './header'
import Footer from './footer'

const siteQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = (props) => {
  return (
    <StaticQuery
      query={`${siteQuery}`}
      render={(data) => (
        <div className='antialiased layout'>
          <Header />
          <main role='main' id='main' className='text-gray-800 text-lg'>
            {props.children}
          </main>
          <Footer />
          <CookieConsent
            location='bottom'
            buttonText='Accept'
            declineButtonText='Decline'
            disableStyles
            buttonClasses='ml-2 rounded-md text-blue-800 bg-white shadow px-4 py-2 border border-transparent leading-6 font-medium transform duration-300 ease-in-out transition'
            declineButtonClasses='ml-2 rounded-md text-white bg-brand-dark-blue shadow px-4 py-2 border border-white leading-6 font-medium transform duration-300 ease-in-out transition'
            containerClasses='fixed inset-x-0 text-white bg-brand-dark-blue p-3 text-center sm:flex justify-center z-10'
            contentClasses='py-2 flex-1'
            enableDeclineButton
            cookieName='gatsby-gdpr-google-tagmanager'>
            We use cookies to collect information about how you use {data.site.siteMetadata.title}. See our{' '}
            <a href='https://bidlogix.freshdesk.com/support/solutions/articles/44001110780-privacy-policy' className='underline' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>{' '}
            to see how we use this information to make the website work as well as possible and improve our services.
          </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string
}

export default Layout
