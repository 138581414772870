import React, { PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import CheckLink from './checkLink'
import Mailchimp from './mailchimp'
import Social from './social'

let newDate = new Date()
let year = newDate.getFullYear()

const footerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
  }
`;

class Footer extends PureComponent {
  componentDidMount() {
    const script=document.createElement('script')
    script.src='https://statuspal.io/js/widget.js'
    script.async=true
    this.instance.appendChild(script)

    if (typeof window !== 'undefined') {
      window.statuspalWidget = {
        subdomain: 'bidlogix',
        selector: '.sp-status',
        position: 'top',
      }
    }
  }

  render () {
    return (
      <StaticQuery
        query={`${footerQuery}`}
        render={data => {
          const footerData = data.prismicNavigation.data
          return (
            <footer role='banner' className='border-t border-gray-200 bg-white text-gray-700'>
              <div className='max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
                <div className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8'>
                  <div className='md:col-span-1'>
                    <h4 className='mt-8 mb-4 font-semibold leading-none '>{footerData.company_display_name}</h4>
                    {footerData.company_address && <div dangerouslySetInnerHTML={{__html:footerData.company_address.html}} />}
                    {footerData.company_phone && (
                      <div className='mt-4'>T: <a className='text-brand-blue' href={`tel:${footerData.company_phone}`}>{footerData.company_phone}</a></div>
                    )}
                    <h4 className='mt-8 mb-4 font-semibold leading-none'>Follow</h4>
                    <div className='flex mt-4'>
                      <Social networks={footerData} />
                    </div>
                  </div>

                  <div className='md:col-span-1'>
                    <h4 className='mt-8 mb-4 font-semibold leading-none'>Useful Links</h4>
                    <div className='sf-root md:w-3/5' data-id='2990277' data-badge='light-partner'>
                      <ul>
                        {footerData.footer_links.map((footerLinks, index) => {
                          return (
                            <li className='pb-4 pr-8 inline-block w-1/2 md:w-full md:block hover:text-brand-blue' key={`footer-${index}`}>
                              <CheckLink className='block focus:outline-none focus:ring-2 focus:ring-opacity-50 ring-blue-500' link={footerLinks.link}>{footerLinks.link_label} {footerLinks.link_label.includes('Status') && (<span className='sp-status'/>)}</CheckLink>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className='md:col-span-2 lg:col-span-1'>
                    <h4 className='mt-8 mb-4  font-semibold leading-none'>Service Reviews</h4>
                    <p className='mt-4 text-base leading-6'>See what people just like you think about Bidlogix.</p>
                    <div className='flex mt-4 justify-center'>
                      <a href='https://www.g2.com/products/bidlogix-bidjs/reviews?utm_source=review-widget' title='Read reviews of BidJS on G2' className='inline-block mt-4 mr-6' target='_blank' rel='noopener noreferrer'>
                        <img className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 transform transition transiton inline h-12 object-contain' alt='Read BidJS reviews on G2' src='https://www.g2.com/products/bidlogix-bidjs/widgets/stars?color=gray&amp;type=read' loading='lazy' height='48' width='auto' />
                      </a>
                      <a href='https://www.capterra.com/reviews/174506/BidJs?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge' className='inline-block mt-4 mr-6' target='_blank' rel='noopener noreferrer'>
                        <img className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 transform transition transiton inline h-12 object-contain' alt='capterra reviews' src='https://assets.capterra.com/badge/adfe872217d3f766ada11c558ea94f80.png?v=2090054&p=174506' loading='lazy'  height='48' width='auto' />
                      </a>
                      <a href='https://sourceforge.net/software/product/BidJS/?pk_campaign=badge&amp;pk_source=vendor' className='inline-block mt-4 mr-6' target='_blank' rel='noopener noreferrer'>
                        <img className='duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 transform transition transiton inline h-12 object-contain' alt='SourceForge Logo' src='https://b.sf-syn.com/badge_img/2990277/light-partner' loading='lazy' height='48' width='auto' />
                      </a>
                    </div>
                    <h4 className='mt-8 mb-4 font-semibold leading-none'>Subscribe</h4>
                    <p className='my-4 text-base leading-6'>Subscribe to our e-mail newsletter to receive product updates.</p>
                    <Mailchimp/>
                  </div>
                </div>
                <div className='mt-12 border-t border-gray-200 pt-8'>
                  <p className='text-base leading-6 text-gray-700 text-center'>
                    {footerData.company_display_name} &copy; {year}. All rights reserved.
                  </p>
                </div>
              </div>
              <div ref={el => (this.instance = el)} />
            </footer>
          )
        }}
      />
    )
  }
}

export default Footer
