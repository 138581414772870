import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Mailchimp extends React.Component {


    state = {
      email: null
    }

    _handleChange = (e) => {
      this.setState({
        [`${e.target.name}`]: e.target.value,
      });
    }
    _handleSubmit = e => {
      e.preventDefault()

      console.log('submit', this.state)
      addToMailchimp(this.state.email)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)
        if (result !== 'success') {
          throw msg
        }
        alert(msg)
      })
      .catch(err => {
        console.log('err', err)
        alert(err)
      })
    }

  render () {
    return (
      <form className='sm:flex' onSubmit={this._handleSubmit}>
        <label className='sr-only' htmlFor='email'>Email Address</label>
        <input className='shadow appearance-none border rounded-t sm:rounded-none sm:rounded-l text-center sm:text-left w-full py-2 px-3 text-gray-700 leading-tight text-base focus:outline-none focus:ring' type='email' name='email' placeholder='email address' id='email' aria-required='true' onChange={this._handleChange} />
        <button className='rounded-b sm:rounded-none sm:rounded-r py-2 text-white bg-brand-blue shadow-md px-4 sm:px-8 w-full sm:w-auto sm:py-3 border border-transparent text-base leading-6 font-medium transform duration-300 ease-in-out transition hover:bg-blue-500' type='submit' name='subscribe' id='mc-embedded-subscribe'>Subscribe</button>
      </form>
    )
  }
}
