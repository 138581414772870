import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import CheckLink from '../components/checkLink'

const socialQuery = graphql`
  {
    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    twitter: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    youtube: file(relativePath: { eq: "youtube.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    linkedin: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    github: file(relativePath: { eq: "github.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Social = ({ networks }) => {
  return (
    <StaticQuery
      query={`${socialQuery}`}
      render={(data) => (
        <div className='space-x-3 flex'>
          {networks.body[0].items.map((social, index) => {
            var socialImage = data[social.social_network.toLowerCase()].childImageSharp.fixed
            return (
              <CheckLink className='focus:outline-none focus:ring-2 focus:ring-opacity-50 ring-blue-500 rounded-full flex' link={social.social_network_url} key={`social-${index}`}>
                <Img fixed={socialImage} alt={social.social_network} className='w-14 h-14 transform hover:scale-110 ease-in duration 300' loading='lazy' />
                <span className='sr-only'>{social.social_network}</span>
              </CheckLink>
            )
          })}
        </div>
      )}
    />
  )
}

Social.propTypes = {
  networks: PropTypes.object.isRequired
}

export default Social
