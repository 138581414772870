import React, { PureComponent } from 'react'
import CheckLink from '../components/checkLink'

class Dropdown extends PureComponent {
  constructor() {
    super()
    this.state = {
      subNavExpanded: false,
    }
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  container = React.createRef();
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = event => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        subNavExpandedId:false
      });
    }
  };

  render() {
    return (
      <li ref={this.container} className='m-2 text-gray-300'>
        <button className='relative text-3xl lg:text-xl flex w-full flex-row justify-start block px-3 py-2 font-medium lg:text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:border-blue-500 focus:text-blue-200 transition duration-150 ease-in-out' aria-haspopup='true' aria-expanded={this.state.subNavExpandedId === this.props.children ? 'true':'false'} onClick={() => this.toggleSubNav(`${this.props.children}`)} onKeyDown={() => this.toggleSubNav(`${this.props.children}`)}>
          {this.props.children}
          <svg xmlns='http://www.w3.org/2000/svg' className='mt-1 ml-1 w-5 md:ml-auto' height='1em' width='1em'>
            <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor'/>
            <path d='M0 0h24v24H0z' fill='none'/>
          </svg>
        </button>
        <ul className={`lg:max-w-lg lg:w-3/4 lg:absolute lg:bg-white lg:shadow-lg ${this.state.subNavExpandedId === this.props.children ? 'block':'hidden'}`}>
          {this.props.items.map((subNav, index) => {
            return (
              <li key={`subNav-${index}`}>
                <CheckLink className='px-10 py-5 lg:p-4 block lg:hover:bg-gray-100 lg:focus:bg-gray-100 transform transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:border-blue-500' link={subNav.sub_nav_link}>
                  <div className='text-xl lg:text-lg block lg:text-brand-blue font-medium focus:text-white'>{subNav.sub_nav_link_label}</div>
                  <p className='lg:text-gray-700 font-thin text-base lg:text-sm lg:hover:text-gray-800'>{subNav.sub_nav_description}</p>
                </CheckLink>
              </li>
            )
          })}
        </ul>
      </li>
    )
  }
}

export default Dropdown
