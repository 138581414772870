import React, { PureComponent } from 'react'
import { Fade as Hamburger } from 'hamburger-react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import CheckLink from './checkLink'
import Dropdown from './dropdown'

const headerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    logoText: file(relativePath: { eq: "bidlogix-logo-white.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    circleCircles: file(relativePath: { eq: "bidlogix-logo-circles.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;


class Header extends PureComponent {

  constructor() {
    super()
    this.state = {
      mainNavExpanded: false,
    }
    this.toggleMainNav = this.toggleMainNav.bind(this);
  }
  toggleMainNav() {
    this.setState({
      mainNavExpanded:!this.state.mainNavExpanded
    })
  }

  render() {
    return (
      <StaticQuery
        query={`${headerQuery}`}
        render={data => {
          const navigationData = data.prismicNavigation.data
          return (
            <header role='banner' className='absolute top-0 left-0 right-0 z-20'>
              <nav id='navigation' className='max-w-screen-xl mx-auto relative flex items-center justify-between lg:justify-between p-4'>
                <Link to='/' className='relative flex flex-row z-20 w-56 lg:w-80 pt-4 mr-5 transform duration-300 ease-in-out transition focus:outline-none focus:ring-2 focus:ring-opacity-50 ring-blue-500'>
                  <div className='w-4/5'>
                    <Img className='w-full object-fit inline-block mr-2' fluid={data.logoText.childImageSharp.fluid} alt={`${navigationData.company_display_name} logo`} />
                  </div>
                  <div className='w-1/5'>
                    <Img className='w-full object-fit inline-block' fluid={data.circleCircles.childImageSharp.fluid} alt='' />
                  </div>
                </Link>
                <span className='lg:hidden z-20'>
                  <Hamburger
                    color='white'
                    label='Show menu'
                    rounded
                    toggled={this.state.mainNavExpanded}
                    toggle={this.toggleMainNav }
                  />
                </span>
                <ul className={`${this.state.mainNavExpanded === false ? ('hidden'):('absolute inset-0 bg-black flex flex-col h-screen justify-center z-10')} lg:flex lg:items-center w-full lg:w-auto`}>
                  {navigationData.nav.map((nav, index) => {
                    return (
                      <React.Fragment key={`nav-${index}`}>
                        {nav.items.length > 1 ? (
                          <Dropdown items={nav.items}>{nav.primary.label}</Dropdown>
                        ):(
                          <li key={`nav-${index}`} className='m-2 text-gray-300'>
                            <CheckLink className='relative text-3xl lg:text-xl flex w-full flex-row justify-start block px-3 py-2 font-medium lg:text-gray-300 hover:text-white transform duration-300 ease-in-out transition focus:outline-none focus:ring-2 focus:ring-opacity-50 ring-blue-500 focus:text-blue-200 transition duration-150 ease-in-out' activeClassName='lg:text-white' link={nav.primary.link} onClick={ this.toggleNav }>{nav.primary.label}</CheckLink>
                          </li>
                        )}
                      </React.Fragment>
                    )
                  })}
                </ul>
              </nav>
            </header>
          )
        }}
      />
    )
  }
}

export default Header
