import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const Quote = ({ slice }) => {

  return (
    <blockquote className={`component-${slice.slice_type} relative md:grid md:grid-flow-col md:gap-8`}>
      <svg fill='currentColor' viewBox='0 0 32 32' className='transform -translate-x-8 -translate-y-3 absolute h-8 left-0 text-brand-dark-blue opacity-75 top-0 w-8'>
        <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z'></path>
      </svg>
      <div className='mb-8 text-xl relative leading-tight font-medium md:flex-grow md:text-3xl md:col-span-1 md:mb-0' dangerouslySetInnerHTML={{__html:slice.primary.quote.html}} />
      <footer className='flex items-center justify-center md:col-span-1 md:flex-col md:text-center'>
        <Img className='mb-3 bg-gray-100 border-2 border-white mr-4 rounded-full w-16 h-16 md:w-24 md:h-24 md:mr-0 md:block md:mb-3 md:flex-col' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt} />
        <div>
          <p className='text-gray-100 font-semibold leading-none text-sm mb-1'>{slice.primary.author}</p>
          <p className='text-gray-200 text-xs'>{slice.primary.role}<br />{slice.primary.company}</p>
        </div>
      </footer>
    </blockquote>
  )
}

Quote.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Quote
